// Libraries
import {DndContext, DragOverlay, closestCenter} from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import React from 'react';
import {createPortal} from 'react-dom';

// Supermove
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import DragAndDropListV1 from './DragAndDropListV1';
import {OnReorderType} from './components/DragAndDropBuilder';
import DragComponent from './components/DragComponent';
import Draggable from './components/Draggable';
import useReorderDragAndDrop from './hooks/useReorderDragAndDrop';
import useSensors from './hooks/useSensors';

const DragAndDropListV2 = ({
  scrollRef,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isHorizontal = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  itemContainerStyle,
  children,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  spaceBetweenItems?: number;
  onReorder?: OnReorderType;
  isHorizontal?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
}) => {
  const {childrenWithIds, itemIds, isDragging, isDraggingId, setIsDraggingId, handleDragEnd} =
    useReorderDragAndDrop({onReorder, children});
  const sensors = useSensors();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragPending={({id}) => setIsDraggingId(id)}
      onDragAbort={() => setIsDraggingId(undefined)}
      onDragEnd={(event) => handleDragEnd(event)}
      autoScroll={{enabled: false}}
    >
      <div
        ref={scrollRef}
        style={{
          flexDirection: isHorizontal ? 'row' : 'column',
          marginLeft: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginRight: isHorizontal ? -spaceBetweenItems / 2 : 0,
          marginTop: isHorizontal ? 0 : -spaceBetweenItems / 2,
          marginBottom: isHorizontal ? 0 : -spaceBetweenItems / 2,
        }}
      >
        <SortableContext
          items={itemIds}
          strategy={isHorizontal ? horizontalListSortingStrategy : verticalListSortingStrategy}
        >
          {React.Children.map(childrenWithIds, (item, index) => {
            const itemId = item.props.id;
            const isDraggingThisItem = isDragging && isDraggingId === itemId;
            const isDisabledIndex = disabledIndexes.includes(index);

            return (
              <Draggable
                key={item.key}
                id={itemId}
                index={index}
                isHorizontal={isHorizontal}
                isGrabbing={!!isDraggingId}
                isDragging={isDraggingThisItem}
                isDragIconHidden={isDisabled || isDisabledIndex || isHorizontal}
                isDragDisabled={isDisabled || isDisabledIndex || isDisabledWithVisibleIcons}
                spaceBetweenItems={spaceBetweenItems}
                itemContainerStyle={itemContainerStyle}
              >
                {item}
              </Draggable>
            );
          })}
        </SortableContext>
      </div>

      {createPortal(
        <DragOverlay style={{cursor: isDragging ? 'grabbing' : 'grab'}}>
          {isDragging && (
            <DragComponent
              spaceBetweenItems={spaceBetweenItems}
              isDisabled={isDisabled}
              disabledIndexes={disabledIndexes}
              itemContainerStyle={itemContainerStyle}
              isDraggingId={isDraggingId}
              isHorizontal={isHorizontal}
              childrenWithIds={childrenWithIds}
            />
          )}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

const DragAndDropList = ({
  scrollRef,
  indexOfEdit,
  spaceBetweenItems = 0,
  onReorder = () => {},
  isReordering = false,
  isDisabled = false,
  isDisabledWithVisibleIcons = false,
  disabledIndexes = [],
  droppableStyle,
  itemContainerStyle,
  children,
  isDragAndDropListV1 = false,
  isHorizontal = false,
}: {
  scrollRef?: React.RefObject<HTMLDivElement>;
  indexOfEdit?: number | null;
  spaceBetweenItems?: number;
  onReorder?: OnReorderType;
  isReordering?: boolean;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
  droppableStyle?: ViewStyleProp;
  itemContainerStyle?: ViewStyleProp;
  children: React.ReactElement[];
  isDragAndDropListV1?: boolean;
  isHorizontal?: boolean;
}) => {
  if (isDragAndDropListV1) {
    return (
      <DragAndDropListV1
        scrollRef={scrollRef}
        indexOfEdit={indexOfEdit}
        spaceBetweenItems={spaceBetweenItems}
        onReorder={onReorder}
        isReordering={isReordering}
        isDisabled={isDisabled}
        isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
        disabledIndexes={disabledIndexes}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        droppableStyle={droppableStyle}
        // @ts-expect-error using ViewStyleProp but expecting CSSProperties
        itemContainerStyle={itemContainerStyle}
      >
        {children}
      </DragAndDropListV1>
    );
  }
  return (
    <DragAndDropListV2
      scrollRef={scrollRef}
      spaceBetweenItems={spaceBetweenItems}
      onReorder={onReorder}
      isHorizontal={isHorizontal}
      isDisabled={isDisabled}
      isDisabledWithVisibleIcons={isDisabledWithVisibleIcons}
      disabledIndexes={disabledIndexes}
      itemContainerStyle={itemContainerStyle}
    >
      {children}
    </DragAndDropListV2>
  );
};

export default DragAndDropList;
