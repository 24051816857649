// Libraries
import {DragEndEvent} from '@dnd-kit/core';
import _ from 'lodash';
import React from 'react';

// Supermove
import {useState} from '@supermove/hooks';

// Relative
import {OnReorderType} from '../components/DragAndDropBuilder';

const useReorderDragAndDrop = ({
  onReorder,
  children,
}: {
  onReorder: OnReorderType;
  children: React.ReactElement[];
}) => {
  const [isDraggingId, setIsDraggingId] = useState<number | string>();
  const childrenWithIds = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      id: child.key,
    });
  });

  const itemIds = React.Children.map(childrenWithIds, (child: {props: {id: string}}) => {
    return child.props.id;
  });

  const handleDragEnd = ({active, over}: DragEndEvent) => {
    const fromIndex = active.data.current?.sortable.index;
    const toIndex = over?.data.current?.sortable.index;

    if (!_.isNil(toIndex) && fromIndex !== toIndex) {
      onReorder({fromIndex, toIndex});
    }
    setIsDraggingId(undefined);
  };

  return {
    childrenWithIds,
    itemIds,
    isDragging: !_.isNil(isDraggingId),
    isDraggingId,
    setIsDraggingId,
    handleDragEnd,
  };
};

export default useReorderDragAndDrop;
