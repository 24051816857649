import {
  DndContext,
  DragOverlay,
  UniqueIdentifier,
  closestCenter,
  useDroppable,
} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import _ from 'lodash';
import React from 'react';
import {createPortal} from 'react-dom';

// Supermove
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// Relative
import Space from '../../Space';
import Styled from '../../Styled';
import Draggable from '../components/Draggable';
import useSectionedDragAndDropList, {SectionType} from '../hooks/useSectionedDragAndDropList';
import useSensors from '../hooks/useSensors';

const ContentContainer = Styled.View`
  flex-direction: row;
`;

const SectionContainer = Styled.View`
  border-radius: 4px;
  background-color: ${colors.gray.background};
`;

const SectionHeaderContainer = Styled.View`
  padding-horizontal: 12px;
`;

const ItemsContainer = Styled.View`
  padding-horizontal: 12px;
`;

const Droppable = ({sectionId}: {sectionId: string}) => {
  const {setNodeRef} = useDroppable({id: sectionId});

  return <div ref={setNodeRef} />;
};

const SectionedDragAndDropList = <T,>({
  spaceBetweenItems = 0,
  handleUpdate = () => {},
  isDisabled = false,
  disabledItemIds = [],
  itemContainerStyle,
  itemIdExtractor = 'id',
  renderItem,
  renderHeader,
  sections,
  sectionContainerStyle,
  sectionHeaderContainerStyle,
}: {
  spaceBetweenItems?: number;
  handleUpdate?: (sections: SectionType<T>[]) => void;
  isDisabled?: boolean;
  disabledItemIds?: UniqueIdentifier[];
  itemContainerStyle?: ViewStyleProp;
  itemIdExtractor?: string;
  renderItem: (item: T) => React.ReactNode;
  renderHeader?: (section: SectionType<T>) => React.ReactNode;
  sections: SectionType<T>[];
  sectionContainerStyle?: ViewStyleProp;
  sectionHeaderContainerStyle?: ViewStyleProp;
}) => {
  const {
    sectionIdToItems,
    grabbedItem,
    isDragging,
    handleDragOver,
    handleDragEnd,
    handleDragPending,
    handleDragAbort,
    handleDragStart,
    getItemId,
  } = useSectionedDragAndDropList({
    sections,
    itemIdExtractor,
    handleUpdate,
  });
  const sensors = useSensors();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragPending={handleDragPending} // On initial grab
      onDragAbort={handleDragAbort} // On grabbed but never dragged
      onDragStart={handleDragStart} // On grab movement
      onDragOver={handleDragOver} // On grab movement over another item
      onDragEnd={handleDragEnd} // On grab released
      autoScroll={{enabled: false}}
    >
      <ContentContainer>
        {sections.map((section, index) => {
          const sectionItems = sectionIdToItems[section.id];

          return (
            <React.Fragment key={`container-${index}`}>
              {index > 0 && <Space width={8} />}
              <SortableContext
                key={`container-${section.id}`}
                items={sectionItems.map((item) => getItemId(item))} // These get assigned as the item ids for drag events
                strategy={verticalListSortingStrategy}
              >
                <SectionContainer style={sectionContainerStyle}>
                  <Space height={12} />
                  {renderHeader && (
                    <React.Fragment>
                      <SectionHeaderContainer style={sectionHeaderContainerStyle}>
                        {renderHeader(section)}
                      </SectionHeaderContainer>
                      <Space height={8} />
                    </React.Fragment>
                  )}
                  <ItemsContainer>
                    {_.some(sectionItems) ? (
                      sectionItems.map((item, index) => {
                        const itemId = getItemId(item);
                        return (
                          <Draggable
                            key={`item-${itemId}`}
                            id={itemId}
                            index={index}
                            spaceBetweenItems={spaceBetweenItems}
                            isHorizontal
                            isGrabbing={!!grabbedItem}
                            isDragging={
                              isDragging && !!grabbedItem && getItemId(grabbedItem) === itemId
                            }
                            isDragIconHidden
                            isDragDisabled={isDisabled || disabledItemIds.includes(itemId)}
                            itemContainerStyle={itemContainerStyle}
                            draggableWrapperStyle={{flex: 1}}
                          >
                            {renderItem(item)}
                          </Draggable>
                        );
                      })
                    ) : (
                      <Droppable sectionId={section.id} />
                    )}
                  </ItemsContainer>
                  <Space height={12} />
                </SectionContainer>
              </SortableContext>
            </React.Fragment>
          );
        })}
      </ContentContainer>

      {createPortal(
        <DragOverlay style={{cursor: grabbedItem ? 'grabbing' : 'grab'}}>
          {grabbedItem && renderItem(grabbedItem)}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};

export default SectionedDragAndDropList;
